@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Encode+Sans+Expanded:wght@100;200;300;400;500;600;700;800;900&family=Major+Mono+Display&family=Ojuju:wght@200..800&display=swap");

html {
  background-color: #1d2b42;
}

body {
  margin: 0px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.App {
  height: 100vh;
  width: 100%;
  margin: none;
  padding: none;
  text-align: center;
  font-family: "Encode Sans Expanded", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.topLines {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-top: 20px;
}

.longLine {
  width: 95vw;
  height: 1vh;
  margin: 1vh 0px;
  background: #3c6e71;
}

.shortLine {
  width: 85vw;
  height: 1vh;
  margin: 1vh 0px;
  background: #d9d9d9;
}

.bottomLines {
  display: flex;
  flex-direction: column;
  align-items: end;
  margin: 1vh;
}

/* 
this is the css for the sticky part */
