.navBar {
  position: fixed;
  top: 0px;
  width: 100vw;
  color: white;
  height: 7vh;
  z-index: 1000000;
  /* background-color: #3C6E71; */
  background-color: #1d2b42bd;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  /* border-bottom: 1vh solid white; */
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.452);
  justify-content: space-between;
  padding: 0 3vh;
  box-sizing: border-box;
  font-size: 1.5vw;
  animation-name: fadeIn;
  animation-duration: 4s;
  /* animation-delay: 20s; */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    top: -5vh;
  }
  to {
    opacity: 1;
  }
}

.options {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 50vw;
  scroll-behavior: smooth;
}

.navBarItem {
  margin: 0 30px;
  font-family: "Encode Sans Expanded", sans-serif;
  font-style: normal;
  font-weight: 400;
  color: white;
  background: none;
  border: 0px;
  padding: 0px;
  font-size: 2vw;
  transition: 0.3s ease-out;
}

.navBarItem:hover {
  color: #3c6e71;
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}
a {
  text-decoration: none;
}

@media (max-width: 760px) {
  .options {
    display: none;
  }

  .navBar {
    font-size: 6vw;
  }
  .brand {
    font-family: "Encode Sans Expanded", sans-serif;
    font-style: normal;
    font-weight: 300;
    color: white;
    border: 0px;
    padding: 0px;
    font-size: 6vw;
    transition: 0.3s ease-out;
  }
}

@media (min-width: 760px) {
  .brand {
    display: none;
  }

  .menu {
    display: none;
  }

  .navBar {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
