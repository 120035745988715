.footer {
  background-color: #3c6e71;
  height: auto;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-family: "Encode Sans Expanded", sans-serif;
  font-style: normal;
  color: white;
  padding: 6vh 0px;
  margin-top: 20px;
}

.footerContent {
  text-align: left;
  width: 40%;
}

.footerLinks {
  text-align: center;
  width: 30%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.socialMedia {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  width: 50%;
}

.footer h2 {
  font-size: 6vh;
  text-shadow: 1px 1px 2px #353535;
  margin: 3vh 0;
}

.footer h3 {
  font-size: 3vh;
  margin: 0px;
}

.footer h4 {
  font-size: 3vh;
  margin: 0px;
  margin-bottom: 20px;
}

.footer h1 {
  font-size: 2vw;
  color: white;
  transition: 0.3s ease-out;
  padding: 10px;
}

.footer h1:hover {
  color: #1d2b42;
  transition: transform 0.3s ease-in-out;
  transform: scale(1.05);
}

a {
  color: #1d2b42;
  transition: 0.3s ease-out;
}

.gitHub {
  height: 8vh;
  aspect-ratio: 1/1;
  background: url(../../img/gitHub.png);
  background-size: contain;
  background-repeat: no-repeat;
  transition: 0.3s ease-out;
}

.linkedIn {
  height: 8vh;
  aspect-ratio: 1/1;
  background: url(../../img/linkediIn.png);
  background-size: contain;
  background-repeat: no-repeat;
  transition: 0.3s ease-out;
}

.socialMedia a:hover {
  transition: transform 0.3s ease-in-out;
  transform: scale(1.05);
  filter: invert(85%) sepia(25%) saturate(1035%) hue-rotate(178deg)
    brightness(102%) contrast(95%);
}

@media (max-width: 760px) {
  .footer {
    flex-direction: column;
    padding: 3vh 0px;
  }

  .footerContent {
    text-align: center;
    width: 80%;
  }

  .footer h2 {
    font-size: 4vh;
  }

  .footer h3 {
    font-size: 1.6vh;
  }

  .footer h4 {
    font-size: 1.6vh;
  }

  .footerLinks {
    width: 80%;
  }

  .footer h1 {
    font-size: 5vw;
  }
}
