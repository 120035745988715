.experiencesPage {
  /* margin: 100px 0px; */
  height: auto;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  background-color: #353535;
}

.experienceContainer {
  margin-top: 5vh;
  margin-bottom: 5vh;
  height: auto;
  width: 80vw;
  display: flex;
  justify-content: top;
  align-items: center;
  flex-direction: column;
  color: white;
  font-size: xx-large;
  background-color: #6c6c6c21;
}

.experienceContainerTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 15vh;
  text-align: center;
  font-size: xx-large;
  color: white;
  font-family: "Encode Sans Expanded", sans-serif;
  font-weight: 100;
  font-style: normal;
  margin-bottom: 3vh;
  margin-top: 5vh;
}

.experiences {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 80vw;
  height: 50vh;
  margin-bottom: 5vh;
}

.experience {
  width: 30%;
  border: 0.5vh solid white;
  height: 100%;
}

.experienceHeader {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 40%;
}

.experienceTitle {
  text-align: left;
  font-size: 2vw;
  color: white;
  border-left: 0.5vh solid white;
  padding-left: 2vw;
}

.experienceDescription {
  text-overflow: clip;
  margin: 2vh;
  color: #d9d9d9;
  text-align: center;
  font-size: 2vw;
}

#experiencePictureOne {
  background: url(../../img/jsLogo.png);
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  width: 40%;
  height: 15vh;
  background-position: center;
}

#experiencePictureTwo {
  background: url(../../img/reactLogo.png);
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  width: 40%;
  height: 15vh;
  background-position: center;
}

#experiencePictureThree {
  background: url(../../img/mongoLogo.png);
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  width: 40%;
  height: 15vh;
  background-position: center;
}

@media (max-width: 760px) {
  .experienceContainerTitle {
    font-size: 8vw;
    margin: 2vh 0;
  }

  .experiences {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .experience {
    width: 90%;
    padding: 2vh;
    margin-top: 2vh;
  }

  .experienceDescription {
    font-size: 4vw;
    margin: 0px;
    margin-top: 2vh;
  }

  .experienceTitle {
    font-size: 5vw;
  }

  .experienceHeader {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
