.personal {
  margin: 100px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.personalPicture {
  aspect-ratio: 1/1;
  width: 40vw;
  background: url(../../img/profilePicture.png);
  border-radius: 50%;
  border: 0.5vh solid white;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.personalInfo {
  aspect-ratio: 1/1;
  width: 40vw;
}

.personalTitle {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  font-size: 5vw;
  color: white;
  font-family: "Encode Sans Expanded", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.titleLineOne {
  width: 100%;
  height: 50%;
  margin: 0px;
  margin-left: 2vh;
  text-align: left;
  box-sizing: border-box;
}

.titleLineTwo {
  width: 100%;
  height: 50%;
  margin: 0px;
  margin-right: 2vh;
  text-align: right;
  box-sizing: border-box;
}

.personalDescription {
  text-overflow: clip;
  margin: 4vh;
  color: #d9d9d9;
  text-align: justify;
  font-size: 1.5vw;
}


@media (max-width: 760px) {
  .personalPicture {
    width: 80vw;
  }

  .personalInfo {
    width: 80vw;
  }
  .personal {
    flex-direction: column;
  }

  .personalTitle {
    font-size: 9vw;
  }

  .personalDescription {
    font-size: 5vw;
    margin: 0px;
    margin-top: 2vh;
  }
}
