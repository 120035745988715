.stickyParent {
  height: 500vh;
}

.sticky {
  overflow: hidden;
  position: sticky;
  top: 0;
  height: 100vh;
}

.scrollSection {
  position: absolute;
  top: 0;
  height: 100%;
  width: 500vw;
  will-change: transform;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5vw;
}

.projectSticky {
  padding-top: 5vh;
  width: 90vw;
  height: 90vh;
  object-fit: cover;
  object-position: center;
}

.project {
  display: flex;
  align-items: center;
  justify-content: center;
}

.projectPicture {
  aspect-ratio: 1/1;
  width: 40vw;
  display: block;
}

#projectOnePicture {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: color;
  transition: 0.3s ease-out;
}

#projectOnePicture video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 10;
  display: block;
}

#projectTwoPicture {
  background: url(../../img/weatherApp_mu.png);
  background-size: contain;
  background-repeat: no-repeat;
  transition: 0.3s ease-out;
}

#projectThreePicture {
  background: url(../../img/Picture1.png);
  background-size: contain;
  background-repeat: no-repeat;
  transition: 0.3s ease-out;
}

#projectFourPicture {
  background: url(../../img/codepal_mu.png);
  background-size: contain;
  background-repeat: no-repeat;
  transition: 0.3s ease-out;
}

#projectFivePicture {
  background: url(../../img/eventb_mu.png);
  background-size: contain;
  background-repeat: no-repeat;
  transition: 0.3s ease-out;
}

.ticketMasterCredit{
  color: white;
  text-decoration: underline;
}

.ticketMasterCredit:hover{
  color: #3c6e71;
}

.projectInfo {
  aspect-ratio: 1/1;
  width: 40vw;
}

.projectPicture {
  transition: 0.5s ease-out;
}

.projectPicture:hover {
  transform: scale(1.1);
}

#projectOnePicture:hover {
  transform: none;
}

.projectButton {
  position: relative;
  height: 5vh;
  width: 25vh;
  font-size: 3vh;
  background-color: rgba(255, 255, 255, 0);
  color: white;
  border: none;
  margin-top: 0px;
  transition: 0.3s ease-out;
}

.projectButton:hover {
  color: #3c6e71;
  transform: scale(1.05);
  transition: transform 0.3s ease-in;
}

.projectTitle {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  font-size: 4vw;
  color: white;
  font-family: "Encode Sans Expanded", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.projectTitleLineOne {
  width: 100%;
  height: 50%;
  margin: 0px;
  margin-left: 2vh;
  text-align: left;
  box-sizing: border-box;
}

.projectTitleLineTwo {
  width: 100%;
  height: 50%;
  margin: 0px;
  margin-right: 2vh;
  text-align: right;
  box-sizing: border-box;
}

.projectDescription {
  text-overflow: clip;
  margin: 4vh;
  color: #d9d9d9;
  text-align: justify;
  font-size: 1.5vw;
  margin-top: 30px;
}

#projectOne {
  font-size: 5vw;
}

@media (max-width: 760px) {
  .projectPicture {
    width: 80vw;
  }

  .projectInfo {
    width: 80vw;
  }

  .project {
    flex-direction: column;
  }

  .projectTitle {
    font-size: 8vw;
  }

  .projectDescription {
    font-size: 4vw;
    margin: 0px;
    margin-top: 2vh;
  }

  #projectOne {
    font-size: 10vw;
  }

  #projectOnePicture {
    /* background-clip: url(../../assets/iphoneMu.mp4); */
    background: url(../../../public/img/iphonePicture.png);
    background-size: contain;
    background-repeat: no-repeat;
    transition: 0.3s ease-out;
  }

  #projectOnePicture video {
    display: none;
  }
}
