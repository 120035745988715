@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Major+Mono+Display&display=swap");

.landingPage {
  background: url(../../../public/img/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  display: block;
  padding-top: 10vh;
  overflow: hidden;
  box-shadow: 3px 3px 30px 5px rgba(0, 0, 0, 0.387);
  position: relative;
}

.parallax {
  pointer-events: none;
  transform: translateX(-50%) translateY(-50%);
  transition: 0.45s cubic-bezier(0.2, 0.49, 0.32, 0.99);
}

.flatIron-img {
  position: absolute;
  width: 35vh;
  top: calc(50% + 10vh);
  left: calc(50% + 40vw);
  z-index: 2;
  animation: flatIronIn 1.5s ease-in;
}

.topClouds-img {
  position: absolute;
  width: 190vh;
  top: calc(50% - 55vh);
  left: calc(50% + 30vw);
  z-index: 3;
  animation: topCloudsIn 1.4s ease-in;
}

.newYorker-img {
  position: absolute;
  width: 150vh;
  top: calc(50% + 35vh);
  left: calc(50% + 25vw);
  z-index: 5;
  animation: newYorkerIn 1.2s ease-in;
}

#firstName {
  position: absolute;
  z-index: 6;
  top: calc(50% - 10vh);
  left: 50%;
  text-transform: uppercase;
  color: white;
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  pointer-events: auto;
  animation: firstIn 1.8s ease-in;
}

#lastName {
  position: absolute;
  z-index: 6;
  top: calc(50% - 10vh);
  left: 50%;
  text-transform: uppercase;
  color: white;
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  pointer-events: auto;
  animation: lastIn 1.8s ease-in;
}

#firstName h2 {
  color: transparent;
}

#lastName h1 {
  color: transparent;
}

.text h1 {
  font-weight: 800;
  font-size: 9vw;
  line-height: 0.5;
  padding: 0;
  margin: 0;
}

.text h2 {
  font-weight: 100;
  font-size: 11vw;
  padding: 0;
  margin: 0;
}

.bottomClouds-img {
  position: absolute;
  width: 150vh;
  top: calc(50% + 20vh);
  left: calc(50% - 30vw);
  z-index: 7;
  animation: bottomCloudsIn 1.3s ease-in;
}

.leftBuildings-img {
  position: absolute;
  width: 100vh;
  top: calc(50% + 10vh);
  left: calc(50% - 30vw);
  z-index: 8;
  animation: leftBuildingsIn 1.1s ease-in;
}

.frontBuilding-img {
  position: absolute;
  width: 50vh;
  top: calc(50% + 40vh);
  left: calc(50% - 5vw);
  z-index: 9;
  animation: frontBuildingIn 0.8s ease-in;
}

@media (max-width: 760px) {
  .landingPage {
    background-size: cover;
    background-position-x: -50vw;
  }

  .flatIron-img {
    width: 20vh;
    top: calc(50% + 10vh);
    left: calc(50% + 35vw);
  }

  .topClouds-img {
    width: 190vh;
    top: calc(50% - 80vh);
    left: calc(50% - 50vw);
  }

  .newYorker-img {
    width: 80vh;
    top: calc(50% + 30vh);
    left: calc(50% + 5vw);
  }

  .bottomClouds-img {
    width: 130vh;
    top: calc(50% + 10vh);
    left: calc(50% - 70vw);
    z-index: 3;
  }

  .leftBuildings-img {
    display: none;
  }

  .frontBuilding-img {
    display: none;
  }

  #firstName {
    top: calc(50% - 20vh);
    left: 50%;
    /* left: calc(50% + 10vw); */
  }

  #lastName {
    top: calc(50% - 20vh);
    left: 50%;
    /* left: calc(50% + 10vw); */
  }

  .text h1 {
    font-size: 15vw;
  }

  .text h2 {
    font-size: 20vw;
  }
}

@keyframes flatIronIn {
  from {
    top: calc(50% + 60vh);
  }
}

@keyframes topCloudsIn {
  from {
    top: calc(50% - 70vh);
  }
}

@keyframes newYorkerIn {
  from {
    top: calc(50% + 85vh);
  }
}

@keyframes firstIn {
  from {
    top: calc(50% + 100vh);
  }
}

@keyframes lastIn {
  from {
    top: calc(50% - 100vh);
  }
}

@keyframes bottomCloudsIn {
  from {
    top: calc(50% + 60vh);
  }
}

@keyframes leftBuildingsIn {
  from {
    top: calc(50% + 50vh);
  }
}

@keyframes frontBuildingIn {
  from {
    top: calc(50% + 75vh);
  }
}
